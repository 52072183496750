import { Extension } from '@tiptap/core';
import type { EditorView } from '@tiptap/pm/view';
import { Plugin, PluginKey } from 'prosemirror-state';
import { Decoration, DecorationSet } from 'prosemirror-view';
import type { Node as ProsemirrorNode } from '@tiptap/pm/model';

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        highlight: {
            addHighlightingWithTextPosition: (
                id: string,
                from: number,
                to: number,
                options: { className?: string; focusedClassName?: string; hoverClassName?: string; onClick?: () => void; onFocus?: () => void; onHover?: () => void },
            ) => ReturnType;
            // deprecated
            // text로 들어온 문자열의 시작 부분이 특정 text노드의 시작점과 일치하고 전체 문자열이 일치하는 경우에만 하이라이트 처리
            addHighlightingWithTextContent: (
                text: string,
                options: {
                    className?: string;
                    focusedClassName?: string;
                    hoverClassName?: string;
                    onClick?: () => void;
                    onFocus?: () => void;
                    onHover?: () => void;
                    onAddFinish?: (data: { id: string; from: number; to: number }) => void;
                },
            ) => ReturnType;
            // guid 기반으로 하이라이트 추가
            addHighlightingWithGuid: (
                id: string,
                guid: string,
                options: {
                    startInlineOffset?: number;
                    endInlineOffset?: number;
                    className?: string;
                    focusedClassName?: string;
                    hoverClassName?: string;
                    onClick?: () => void;
                    onFocus?: () => void;
                    onHover?: () => void;
                    onAddFinish?: (data: {
                        id: string;
                        from: number;
                        to: number;
                        relativePositions?: {
                            start: { nodeGuid: string; offset: number };
                            end: { nodeGuid: string; offset?: number };
                        };
                    }) => void;
                },
            ) => ReturnType;
            // guid 기반으로 하이라이트 추가
            addHighlightingWithGuidRange: (
                id: string,
                startGuid: string,
                endGuid: string,
                options: {
                    startInlineOffset?: number;
                    endInlineOffset?: number;
                    className?: string;
                    focusedClassName?: string;
                    hoverClassName?: string;
                    onClick?: () => void;
                    onFocus?: () => void;
                    onHover?: () => void;
                    onAddFinish?: (data: {
                        id: string;
                        from: number;
                        to: number;
                        relativePositions?: {
                            start: { nodeGuid: string; offset: number };
                            end: { nodeGuid: string; offset?: number };
                        };
                    }) => void;
                },
            ) => ReturnType;
            removeAllHighlighting: () => ReturnType;
            removeHighlighting: (id: string) => ReturnType;
            setFocusHighlighting: (id: string) => ReturnType;
            unsetFocusHighlighting: () => ReturnType;
            scrollIntoFocusedHighlighting: (option?: ScrollIntoViewOptions) => ReturnType;
            toggleHoverEnabled: () => ReturnType;
        };
    }
}

interface Highlight {
    id: string;
    from: number;
    to: number;
    className: string;
    focusedClassName: string;
    hoverClassName: string;
    onClick: () => void;
    onFocus: () => void;
    onHover: () => void;
}

interface Range {
    point: number;
    start: boolean;
    highlight: Highlight;
}

interface DecorationData {
    from: number;
    to: number;
    attributes: {
        [key: string]: string;
    };
}

const HIGHLIGHT_ATTR_NAME = 'data-highlight-ids';
const HIGHLIGHT_DEFAULT_CLASS = 'bg-gray-300';
const HIGHLIGHT_DEFAULT_FOCUSED_CLASS = 'bg-yellow-200';
const HIGHLIGHT_FOCUSED_ATTR_NAME = 'data-highlight-focused-ids';
const HIGHLIGHT_DEFAULT_HOVER_CLASS = 'bg-yellow-300';
const HIGHLIGHT_HOVER_ATTR_NAME = 'data-highlight-hover-ids';

const exceptAdjustPositionNodeTypes = ['headerFooter']; // adjustPostion을 구할때 전체 사이즈를 BE에서 통으로 제외시키고 계산한 노드타입

// text의 갯수 위주로 계산된 BE 에서의 포지션값을 tiptap 에서 적용가능한 range start 포지션 값으로 변환
function adjustStartPosition(doc, pos) {
    let adjustedPos = pos;
    let stop = false;
    let expectedCurrentPos = 0; // 실제 pos과 비교하여 depth를 구하기 위해 현재 예상 pos를 추적

    doc.descendants((node, nodePos) => {
        if (stop) return;
        if (exceptAdjustPositionNodeTypes.includes(node.type.name)) {
            adjustedPos += node.nodeSize;
            expectedCurrentPos += node.nodeSize;
            return false;
        }
        const depth = expectedCurrentPos - nodePos;

        if (node.isText && nodePos + node.nodeSize > adjustedPos - depth) {
            adjustedPos -= depth;
            stop = true;
            return; // 순회 중지
        }

        if (node.type.name === 'hardBreak') {
            adjustedPos += 1; // hardBreak 위치값 1 증가
        } else if (node.isBlock) {
            adjustedPos += 2; // 블록 노드의 시작에서 위치값 1 증가
        }

        if (node.type.name === 'hardBreak') expectedCurrentPos += 2;
        else if (node.isBlock) expectedCurrentPos += 2;
        else if (node.isText) expectedCurrentPos += node.nodeSize;
    });

    return adjustedPos;
}

// text의 갯수 위주로 계산된 BE 에서의 포지션값을 tiptap 에서 적용가능한 range end 포지션 값으로 변환
function adjustEndPosition(doc, pos) {
    let adjustedPos = pos;
    let stop = false;
    let expectedCurrentPos = 0; // 실제 pos과 비교하여 depth를 구하기 위해 현재 예상 pos를 추적

    doc.descendants((node, nodePos) => {
        if (stop) return;
        if (exceptAdjustPositionNodeTypes.includes(node.type.name)) {
            adjustedPos += node.nodeSize;
            expectedCurrentPos += node.nodeSize;
            return false;
        }
        const depth = expectedCurrentPos - nodePos;

        if (node.isText && nodePos + node.nodeSize >= adjustedPos - depth) {
            adjustedPos -= depth;
            stop = true;
            return; // 순회 중지
        }

        if (node.type.name === 'hardBreak') {
            adjustedPos += 1; // hardBreak 위치값 1 증가
        } else if (node.isBlock) {
            adjustedPos += 2; // 블록 노드의 시작에서 위치값 1 증가
        }

        if (node.type.name === 'hardBreak') expectedCurrentPos += 2;
        else if (node.isBlock) expectedCurrentPos += 2;
        else if (node.isText) expectedCurrentPos += node.nodeSize;
    });
    return adjustedPos;
}

// 겹치는 영역의 하이라이트를 처리하기 위해 겹치는 부분은 별도로 하나의 하이라이트로 합치고 겹치지 않는 부분은 안겹친 별도의 하이라이트로 처리
const createDecorationData = (highlights: Highlight[]): DecorationData[] => {
    // 모든 하이라이트 범위를 포함하는 배열 생성
    const ranges: Range[] = highlights.reduce((acc, highlight) => {
        acc.push({ point: highlight.from, start: true, highlight });
        acc.push({ point: highlight.to, start: false, highlight });
        return acc;
    }, [] as Range[]);

    // 범위 기준으로 정렬
    ranges.sort((a, b) => a.point - b.point);

    const activeHighlights = new Set<Highlight>();
    const decorationData: DecorationData[] = [];
    let lastPoint = 0;

    ranges.forEach(range => {
        const currentPoint = range.point;

        // 새로운 하이라이트 영역 시작
        if (activeHighlights.size > 0 && lastPoint !== currentPoint) {
            const combinedClasses = Array.from(activeHighlights).reduce((classes, highlight) => {
                if (!classes.includes(highlight.className)) {
                    classes.push(highlight.className);
                }
                return classes;
            }, [] as string[]);
            const combinedFocusedClasses = Array.from(activeHighlights).reduce((classes, highlight) => {
                if (!classes.includes(highlight.focusedClassName)) {
                    classes.push(highlight.focusedClassName);
                }
                return classes;
            }, [] as string[]);
            const combinedHoverClasses = Array.from(activeHighlights).reduce((classes, highlight) => {
                if (!classes.includes(highlight.hoverClassName)) {
                    classes.push(highlight.hoverClassName);
                }
                return classes;
            }, [] as string[]);

            decorationData.push({
                from: lastPoint,
                to: currentPoint,
                attributes: {
                    [HIGHLIGHT_ATTR_NAME]: Array.from(activeHighlights)
                        .map(h => h.id)
                        .join(','),
                    class: combinedClasses.join(' '),
                    focusedClass: combinedFocusedClasses.join(' '),
                    hoverClass: combinedHoverClasses.join(' '),
                    // activeHighlightIds 중에 to가 가장 작은 id
                    [HIGHLIGHT_FOCUSED_ATTR_NAME]: Array.from(activeHighlights).reduce((prev, curr) => (prev.to < curr.to ? prev : curr)).id,
                    [HIGHLIGHT_HOVER_ATTR_NAME]: Array.from(activeHighlights).reduce((prev, curr) => (prev.to < curr.to ? prev : curr)).id,
                },
            });
        }

        // 하이라이트 영역 갱신
        if (range.start) {
            activeHighlights.add(range.highlight);
        } else {
            activeHighlights.delete(range.highlight);
        }

        lastPoint = currentPoint;
    });

    return decorationData;
};

const highlightPluginKey = new PluginKey('highlight');

export function createHighlightExtensionInstance() {
    const HighlightExtension = Extension.create<{
        className: string;
        focusedClassName: string;
        hoverClassName: string;
        enableHover: boolean;
    }>({
        name: 'highlight',

        addOptions() {
            return {
                className: HIGHLIGHT_DEFAULT_CLASS,
                focusedClassName: HIGHLIGHT_DEFAULT_FOCUSED_CLASS,
                hoverClassName: HIGHLIGHT_DEFAULT_HOVER_CLASS,
                enableHover: true, // 호버 이벤트 활성화 여부 제어 옵션 추가
            };
        },

        addStorage() {
            return {
                highlights: [],
                focusedId: '',
                hoverId: '',
            };
        },

        addCommands() {
            return {
                addHighlightingWithTextPosition:
                    (id: string, from: number, to: number, options) =>
                    ({ tr, dispatch }) => {
                        this.storage.highlights = this.storage.highlights.concat({
                            id,
                            from: adjustStartPosition(tr.doc, from),
                            to: adjustEndPosition(tr.doc, to),
                            className: options.className || this.options.className,
                            focusedClassName: options.focusedClassName || this.options.focusedClassName,
                            hoverClassName: options.hoverClassName || this.options.hoverClassName,
                            onClick: options.onClick || (() => {}),
                            onFocus: options.onFocus || (() => {}),
                            onHover: options.onHover || (() => {}),
                        });
                        tr.setMeta(highlightPluginKey, { decoChange: true });
                        if (dispatch) dispatch(tr);
                        return true;
                    },
                addHighlightingWithTextContent:
                    (text: string, options) =>
                    ({ tr, state, dispatch }) => {
                        // text로 들어온 문자열이 여러노드에 걸쳐서 나누어져 있을 수 있으므로 전체 노드를 순회하면서 text를 찾아서 하이라이트 처리
                        const { doc, schema, selection } = state;
                        const findText = text.replaceAll('\n', '');
                        let findedStartPos = 0;
                        let findedEndPos = 0;
                        let findingText = '';
                        doc.descendants((node, pos) => {
                            if (findedEndPos !== 0) return;
                            if (node.isText && node.text) {
                                if (findingText) {
                                    findingText += node.text;
                                    if (findText.startsWith(findingText)) {
                                        if (findingText === findText) {
                                            findedEndPos = pos + node.text.length;
                                        } else {
                                            return;
                                        }
                                    } else {
                                        findingText = '';
                                        findedStartPos = 0;
                                        findedEndPos = 0;
                                        return false;
                                    }
                                }
                                if (findText.startsWith(node.text)) {
                                    findingText = node.text;
                                    findedStartPos = pos;
                                    if (findingText === findText) {
                                        findedEndPos = pos + node.text.length;
                                    }
                                    return;
                                }
                            }
                        });
                        if (findedEndPos !== 0) {
                            const id = crypto.randomUUID();
                            this.storage.highlights = this.storage.highlights.concat({
                                id,
                                from: findedStartPos,
                                to: findedEndPos,
                                className: options.className || this.options.className,
                                focusedClassName: options.focusedClassName || this.options.focusedClassName,
                                hoverClassName: options.hoverClassName || this.options.hoverClassName,
                                onClick: options.onClick || (() => {}),
                                onFocus: options.onFocus || (() => {}),
                                onHover: options.onHover || (() => {}),
                            });
                            options.onAddFinish?.({ id, from: findedStartPos, to: findedEndPos });
                            tr.setMeta(highlightPluginKey, { decoChange: true });
                            if (dispatch) dispatch(tr);
                        } else {
                            options.onAddFinish?.({ id: '', from: 0, to: 0 });
                        }
                        return true;
                    },
                addHighlightingWithGuid:
                    (id, guid, options) =>
                    ({ tr, state, dispatch }) => {
                        const { doc } = state;
                        const { startInlineOffset, endInlineOffset } = options;
                        let targetNode: ProsemirrorNode | null = null;
                        let targetNodePos = 0;

                        // guid에 해당하는 노드 찾기
                        doc.descendants((node, pos) => {
                            if (node.attrs.guid === guid) {
                                targetNode = node as ProsemirrorNode;
                                targetNodePos = pos;
                                return false; // 노드를 찾았으므로 순회 중단
                            }
                        });

                        // 노드를 찾은 경우에만 처리
                        if (targetNode) {
                            const node = targetNode as ProsemirrorNode;
                            // 시작 위치: 노드 내에서의 상대적 위치 계산
                            const relativeStartPos = startInlineOffset !== undefined ? adjustStartPosition(node, startInlineOffset) : 0;

                            // 종료 위치: 노드 내에서의 상대적 위치 계산
                            const relativeEndPos = endInlineOffset !== undefined ? adjustEndPosition(node, endInlineOffset) : node.nodeSize;

                            // 절대 위치로 변환
                            const absoluteStartPos = targetNodePos + relativeStartPos + (node.isBlock ? 1 : 0);
                            const absoluteEndPos = Math.min(targetNodePos + relativeEndPos + (node.isBlock ? 1 : 0), targetNodePos + node.nodeSize);

                            // highlights 배열에 추가할 새로운 하이라이트 객체
                            const newHighlight = {
                                id,
                                from: absoluteStartPos,
                                to: absoluteEndPos,
                                className: options.className || this.options.className,
                                focusedClassName: options.focusedClassName || this.options.focusedClassName,
                                hoverClassName: options.hoverClassName || this.options.hoverClassName,
                                onClick: options.onClick || (() => {}),
                                onFocus: options.onFocus || (() => {}),
                                onHover: options.onHover || (() => {}),
                                // 상대적 위치 정보 추가
                                relativePositions: {
                                    start: {
                                        nodeGuid: guid,
                                        offset: relativeStartPos,
                                    },
                                    end: {
                                        nodeGuid: guid,
                                        offset: relativeEndPos,
                                    },
                                },
                            };

                            this.storage.highlights = this.storage.highlights.concat(newHighlight);

                            options.onAddFinish?.({
                                id,
                                from: absoluteStartPos,
                                to: absoluteEndPos,
                                relativePositions: newHighlight.relativePositions,
                            });

                            tr.setMeta(highlightPluginKey, { decoChange: true });
                            if (dispatch) dispatch(tr);
                            return true;
                        } else {
                            options.onAddFinish?.({ id: '', from: 0, to: 0 });
                        }
                        return true;
                    },
                addHighlightingWithGuidRange:
                    (id, startGuid, endGuid, options) =>
                    ({ tr, state, dispatch }) => {
                        const { doc } = state;
                        const { startInlineOffset, endInlineOffset } = options;
                        let startNode: ProsemirrorNode | null = null;
                        let endNode: ProsemirrorNode | null = null;
                        let startNodePos = 0;
                        let endNodePos = 0;

                        // 먼저 시작과 끝 노드를 찾습니다
                        doc.descendants((node, pos) => {
                            if (node.attrs.guid === startGuid) {
                                startNode = node as ProsemirrorNode;
                                startNodePos = pos;
                            }
                            if (node.attrs.guid === endGuid) {
                                endNode = node as ProsemirrorNode;
                                endNodePos = pos;
                            }
                        });

                        // 노드들을 찾은 경우에만 처리
                        if (startNode && endNode) {
                            // 시작 위치: 시작 노드 내에서의 상대적 위치 계산
                            const relativeStartPos = startInlineOffset !== undefined ? adjustStartPosition(startNode, startInlineOffset) : 0;

                            // 종료 위치: 종료 노드 내에서의 상대적 위치 계산
                            const relativeEndPos = endInlineOffset !== undefined ? adjustEndPosition(endNode, endInlineOffset) : endNode.nodeSize;

                            // 절대 위치로 변환
                            const absoluteStartPos = startNodePos + relativeStartPos + (startNode.isBlock ? 1 : 0);
                            const absoluteEndPos = endNodePos + (endInlineOffset !== undefined ? relativeEndPos + (endNode.isBlock ? 1 : 0) : endNode.nodeSize);

                            // highlights 배열에 추가할 새로운 하이라이트 객체
                            const newHighlight = {
                                id,
                                from: absoluteStartPos,
                                to: absoluteEndPos,
                                className: options.className || this.options.className,
                                focusedClassName: options.focusedClassName || this.options.focusedClassName,
                                hoverClassName: options.hoverClassName || this.options.hoverClassName,
                                onClick: options.onClick || (() => {}),
                                onFocus: options.onFocus || (() => {}),
                                onHover: options.onHover || (() => {}),
                                // 상대적 위치 정보 추가 (endInlineOffset이 없으면 offset 정보를 저장하지 않음)
                                relativePositions: {
                                    start: {
                                        nodeGuid: startGuid,
                                        offset: relativeStartPos,
                                    },
                                    end: {
                                        nodeGuid: endGuid,
                                        ...(endInlineOffset !== undefined && { offset: relativeEndPos }),
                                    },
                                },
                            };

                            this.storage.highlights = this.storage.highlights.concat(newHighlight);

                            options.onAddFinish?.({
                                id,
                                from: absoluteStartPos,
                                to: absoluteEndPos,
                                relativePositions: newHighlight.relativePositions,
                            });

                            tr.setMeta(highlightPluginKey, { decoChange: true });
                            if (dispatch) dispatch(tr);
                            return true;
                        }
                        return true;
                    },
                removeAllHighlighting:
                    () =>
                    ({ tr, dispatch }) => {
                        this.storage.highlights = [];
                        tr.setMeta(highlightPluginKey, { decoChange: true });
                        if (dispatch) dispatch(tr);
                        return true;
                    },
                removeHighlighting:
                    (id: string) =>
                    ({ tr, dispatch }) => {
                        this.storage.highlights = this.storage.highlights.filter(highlight => highlight.id !== id);
                        tr.setMeta(highlightPluginKey, { decoChange: true });
                        if (dispatch) dispatch(tr);
                        return true;
                    },
                setFocusHighlighting:
                    (id: string) =>
                    ({ tr, dispatch, view }) => {
                        this.storage.focusedId = id;
                        tr.setMeta(highlightPluginKey, { decoChange: true });
                        if (dispatch) dispatch(tr);
                        return true;
                    },
                unsetFocusHighlighting:
                    () =>
                    ({ tr, dispatch }) => {
                        this.storage.focusedId = '';
                        this.storage.hoverId = '';
                        tr.setMeta(highlightPluginKey, { decoChange: true });

                        if (dispatch) dispatch(tr);
                        return true;
                    },
                scrollIntoFocusedHighlighting:
                    (option = { behavior: 'smooth', block: 'center' }) =>
                    ({ tr, dispatch, view }) => {
                        view.dom.querySelector(`span[${HIGHLIGHT_ATTR_NAME}*="${this.storage.focusedId}"]`)?.scrollIntoView(option);
                        if (dispatch) dispatch(tr);
                        return true;
                    },
                toggleHoverEnabled:
                    () =>
                    ({ tr, dispatch }) => {
                        this.options.enableHover = !this.options.enableHover; // 호버 활성화 상태 토글
                        if (dispatch) dispatch(tr);
                        return true;
                    },
            };
        },

        addProseMirrorPlugins() {
            return [
                new Plugin({
                    key: highlightPluginKey,
                    state: {
                        init: (config, { doc }) => {
                            const decoData = createDecorationData(this.storage.highlights);
                            const decorations = decoData.map(({ from, to, attributes }) => {
                                return Decoration.inline(from, to, {
                                    ...attributes,
                                    class: attributes[HIGHLIGHT_FOCUSED_ATTR_NAME].indexOf(this.storage.focusedId) !== -1 ? attributes.focusedClass : attributes.class,
                                });
                            });
                            return DecorationSet.create(doc, decorations);
                        },
                        apply: (tr, value, oldState, newState) => {
                            const meta = tr.getMeta(highlightPluginKey);
                            if (!tr.docChanged && !meta) {
                                return value; // 문서에 변경이 없다면 기존 상태를 유지
                            }

                            const mapping = tr.mapping;

                            // 각 하이라이트에 대해 위치 업데이트
                            this.storage.highlights = this.storage.highlights.map(hl => {
                                // 상대적 위치 정보가 있는 경우
                                if (hl.relativePositions) {
                                    let newFrom = 0;
                                    let newTo = 0;
                                    let startFound = false;
                                    let endFound = false;

                                    // 문서를 순회하며 새로운 절대 위치 계산
                                    tr.doc.descendants((node, pos) => {
                                        if (node.attrs.guid === hl.relativePositions.start.nodeGuid) {
                                            newFrom = pos + adjustStartPosition(node, hl.relativePositions.start.offset) + (node.isBlock ? 1 : 0);
                                            startFound = true;
                                        }
                                        if (node.attrs.guid === hl.relativePositions.end.nodeGuid) {
                                            // end offset이 지정되지 않은 경우 노드의 전체 범위 사용
                                            if (hl.relativePositions.end.offset !== undefined) {
                                                newTo = pos + adjustEndPosition(node, hl.relativePositions.end.offset) + (node.isBlock ? 1 : 0);
                                            } else {
                                                newTo = pos + node.nodeSize;
                                            }
                                            endFound = true;
                                        }
                                    });

                                    // 노드를 모두 찾은 경우에만 새로운 위치 사용
                                    if (startFound && endFound) {
                                        return {
                                            ...hl,
                                            from: newFrom,
                                            to: newTo,
                                        };
                                    }
                                }

                                // 상대적 위치 정보가 없거나, 노드를 찾지 못한 경우 기존 매핑 사용
                                return {
                                    ...hl,
                                    from: mapping.map(hl.from),
                                    to: mapping.map(hl.to, -1),
                                };
                            });

                            const decoData = createDecorationData(this.storage.highlights);
                            const decorations = decoData.map(({ from, to, attributes }) => {
                                return Decoration.inline(from, to, {
                                    ...attributes,
                                    class:
                                        this.storage.hoverId && attributes[HIGHLIGHT_ATTR_NAME].indexOf(this.storage.hoverId) > -1
                                            ? attributes.hoverClass
                                            : this.storage.focusedId && attributes[HIGHLIGHT_ATTR_NAME].indexOf(this.storage.focusedId) !== -1
                                              ? attributes.focusedClass
                                              : attributes.class,
                                });
                            });

                            return DecorationSet.create(tr.doc, decorations);
                        },
                    },
                    props: {
                        handleClick: (view: EditorView, pos: number, event: MouseEvent) => {
                            const highlights = this.storage.highlights;
                            if (highlights.length === 0) return;

                            const clickedHighlights: Highlight[] = highlights.filter(({ from, to }) => pos >= from + 1 && pos < to);
                            if (clickedHighlights.length === 0) return;

                            // TODO: 여러개의 하이라이트가 겹쳐있을 경우 hover 된 영역 한개만 onClick 이벤트를 받도록 수정할 필요가 있을지? 아니면 추가 팝업이 뜨도록 할지?
                            clickedHighlights.map(clickedHighlight => {
                                clickedHighlight.onClick();
                            });

                            // 여러개의 하이라이트가 겹쳐있을 경우 to - from 의 범위가 가장 작은 하이라이트만 선택
                            this.storage.focusedId = clickedHighlights.reduce((prev, curr) => (prev.to - prev.from < curr.to - curr.from ? prev : curr), { id: '', from: 0, to: Infinity }).id;
                            view.dispatch(view.state.tr.setMeta(highlightPluginKey, { decoChange: true }));
                        },
                        decorations(state) {
                            return this.getState(state);
                        },
                    },
                    view: view => {
                        const handleMouseOver = (event: MouseEvent) => {
                            if (!this.options.enableHover) return; // enableHover 옵션 체크

                            const highlights = this.storage.highlights;
                            if (highlights.length === 0) return;

                            const { target } = event;
                            if (target.nodeType === Node.ELEMENT_NODE) {
                                const span = target.closest(`span[${HIGHLIGHT_ATTR_NAME}]`);
                                const highlightId = span?.getAttribute(HIGHLIGHT_HOVER_ATTR_NAME);
                                if (this.storage.hoverId === highlightId) return;
                                this.storage.hoverId = highlightId;
                                view.dispatch(view.state.tr.setMeta(highlightPluginKey, { decoChange: true }));

                                // hover 이벤트 발생시 onHover 이벤트 호출
                                const highlights = this.storage.highlights;
                                const hoveredHighlights: Highlight[] = highlights.filter(({ id }) => id === highlightId);
                                hoveredHighlights.map(hoveredHighlight => {
                                    hoveredHighlight.onHover();
                                });
                            }
                        };

                        view.dom.addEventListener('mouseover', handleMouseOver);

                        return {
                            update: (view, prevState) => {
                                const { state } = view;
                                if (state.selection !== prevState.selection) {
                                    // 커서가 이동했을때
                                    const selection = document.getSelection();
                                    if (selection?.rangeCount) {
                                        const range = selection.getRangeAt(0);
                                        if (range.startContainer.nodeType === Node.TEXT_NODE) {
                                            const focusedId = range.startContainer.parentElement?.getAttribute(HIGHLIGHT_FOCUSED_ATTR_NAME);
                                            if (focusedId) {
                                                // storage.focusId 에 현재 포커스된 하이라이트 id를 저장
                                                this.storage.focusedId = focusedId;
                                                // dispatch를 하게되면 트랜잭션이 실행되며 trackchange의 동작에 영향을 미쳐서 주석처리
                                                // view.dispatch(view.state.tr.setMeta(highlightPluginKey, { decoChange: true }));

                                                // onFocus 이벤트 호출
                                                const highlights = this.storage.highlights;
                                                const focusedHighlights: Highlight[] = highlights.filter(({ id }) => id === focusedId);
                                                focusedHighlights.map(focusedHighlight => {
                                                    focusedHighlight.onFocus();
                                                });
                                            }
                                        }
                                    }
                                }
                            },
                            destroy() {
                                view.dom.removeEventListener('mouseover', handleMouseOver);
                            },
                        };
                    },
                }),
            ];
        },
    });
    return HighlightExtension;
}
